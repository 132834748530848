import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    balconColorLogo: file(
      relativePath: { eq: "balcon-menu-full-color-logo.png" }
    ) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Header = () => {
  const imgData = useStaticQuery(getImages)

  return (
    <header className="header-nav" style={{ position: "relative" }}>
      <section className="top-nav">
        <div>
          <Link to="/">
            {/* <img className='nav-logo' src={balcon_logo} alt="balcon full color logo" />   */}
            <Img
              fixed={imgData.balconColorLogo.childImageSharp.fixed}
              alt="Rasmussen Mechanical Services white logo against a dark background"
            />
          </Link>
        </div>
        <input id="menu-toggle" type="checkbox" />
        <label className="menu-button-container" htmlFor="menu-toggle">
          <div className="menu-button"></div>
        </label>
        <ul className="menu main-type main-type__secondary">
          <li>
            <Link
              className="main-type main-type__black"
              to="/services/lab-safety/"
              activeClassName="links-menu__active main-type__black"
              partiallyActive={true}
            >
              Lab Safety
            </Link>
          </li>
          <li>
            <Link
              className="main-type main-type__black"
              to="/services/tab-testing/"
              activeClassName="links-menu__active"
            >
              TAB Testing
            </Link>
          </li>
          <li>
            <Link
              to="/blog/"
              className="main-type main-type__black"
              activeClassName="links-menu__active"
              partiallyActive={true}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="/contact-us/"
              className="main-type main-type__black"
              activeClassName="links-menu__active"
              partiallyActive={true}
            >
              Contact
            </Link>
          </li>
        </ul>
      </section>
    </header>
  )
}

export default Header
